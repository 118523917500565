import gql from 'graphql-tag'

const announceCenter = {
    // 查询公告列表
    publishedArticleIndexService_searchByChannel: gql`query publishedArticleIndexService_searchByChannel($first: Int!, $offset: Float!, $channelId: String){
        publishedArticleIndexService_searchByChannel(first: $first offset: $offset channelId: $channelId){
            edges{
                node{
                    id     
                    articleType       
                    department        
                    outLink  
                    outLinkUrl      
                    path       
                    title       
                    titleImg      
                    top       
                    rePublishDateTime     
                    read
                    createDateTime
                    department
                    endDateTime
                    curChannel{
                        id
                        path
                        name
                        parentId
                    }
                }
            }
            totalCount
        }
    }`,
    // 查询公告分类
    publishedChannelService_loadAllChannels: gql`query publishedChannelService_loadAllChannels{  
        publishedChannelService_loadAllChannels{            
             edges{
                node{
                   id      
                   code       
                   createDateTime      
                   creatorId        
                   department    
                   lastModifiedDateTime
                   memo      
                   name       
                   parent_id       
                   path       
                   status
                }
             }
        }
    }`,
    // 查询公告详情
    publishedArticleService_articleDetail: gql`query publishedArticleService_articleDetail($id: String) {  
        publishedArticleService_articleDetail(id:$id){            
            entity{
                id
                title
                content
                department
                createDateTime
                endDateTime
                readNumber
                readUserNumber
            }
        }
    }`,
    // 查询公告附件
    attachmentService_getArticleAttachment: gql`query attachmentService_getArticleAttachment($articleId: String!) {  
        attachmentService_getArticleAttachment(articleId: $articleId){            
            edges {      
                node {        
                    id        
                    article_id        
                    contentType        
                    createDateTime        
                    creatorId        
                    lastModifiedDateTime        
                    name        
                    url    
                }
            }
        }
    }`
}
export default announceCenter;