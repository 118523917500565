import {vuexOidcCreateStoreModule} from 'vuex-oidc'
import {oidcSettings} from '../config/oidc'
import todoCenter from './todoCenter' //待办中心
import appCenter from "./appCenter" //应用中心
import announceCenter from "./announceCenter" //公告中心
import scheduleCenter from "./scheduleCenter" //日程中心
import notificationCenter from "./notificationCenter" //提醒中心
import $users from "@/graphql/users"

const state = {
    theme: "monochrome",//主题
    userInfo: null,
    position: {},
    isOpenDrawerVisiable: false,  // 控制抽屉中的 index 页面
    direction: 'rtl',  // 控制抽屉中的 index 页面显示方向
    isAredlyTop: 'yes',  // 移动端主体部分是否触顶
    isHoverShowNav: false, // 悬浮显示菜单
    canvasmenusList: []
}
const getters = {
    getIsOpenDrawerVisiable: () => state.isOpenDrawerVisiable,
    getDirection: () => state.direction,
    getIsAredlyTop: () => state.isAredlyTop,
    getPosition: () => state.position
}

const actions = {
    getLoginInfo({state}) {
        apolloProvider.clients.iam.query({
            client: 'iam',
            query: $users.me,
            fetchPolicy: "no-cache",
        }).then(res => {
            state.userInfo = res.data.me;
        }).catch(error => {
            console.log(error);
        })
    },
    // 获取岗位信息
    getIdentity({state}, id) {
        apolloProvider.clients.iam.query({
            client: 'iam',
            fetchPolicy: "network-only",
            query: $users.users,
            variables: {
                first: 1,
                offset: 0,
                filter: {
                    id: {
                        eq: id
                    }
                }
            }
        }).then(res => {
            state.position = res.data.users.edges[0].node;
        }).catch(err => {
            console.log(err);
        })
    }
}

const mutations = {
    updateDrawerVisiable(state, value) {
        if (value.isAutoChange) {
            state.isOpenDrawerVisiable = false;
        } else {
            state.isOpenDrawerVisiable = !state.isOpenDrawerVisiable;
        }
        state.direction = value.type;
    },
    updateIsAredlyTop(state, value) {
        state.isAredlyTop = value;
    },
    updateIsHoverShowNav(state) {
        state.isHoverShowNav = !state.isHoverShowNav;
    },
    updateCanvasmenusList(state, list) {
        state.canvasmenusList = list;
    }
}

const modules = {
    todoCenter: todoCenter,
    appCenter: appCenter,
    announceCenter: announceCenter,
    scheduleCenter: scheduleCenter,
    notificationCenter: notificationCenter,
    oidcStore: vuexOidcCreateStoreModule(
        oidcSettings,
        // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
        // spread your oidcSettings and define a userStore of your choice
        // {
        //     ...oidcSettings,
        //     //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
        // },
        // Optional OIDC store settings
        {
            namespaced: true,
            dispatchEventsOnWindow: true

        },
        // Optional OIDC event listeners
        {
            userLoaded: (user) => console.log('OIDC user is loaded:', user),
            userUnloaded: () => console.log('OIDC user is unloaded'),
            accessTokenExpiring: () => console.log('Access token will expire'),
            accessTokenExpired: () => console.log('Access token did expire'),
            silentRenewError: () => console.log('OIDC user is unloaded'),
            userSignedOut: () => console.log('OIDC user is signed out'),
            oidcError: (payload) => console.log('OIDC error', payload)
        }
    )

}

export default {
    modules,
    state,
    getters,
    actions,
    mutations
}