<!--
  抽屉显示路径：
  ltr 从左往右 rtl 从右往左  ttb 从上往下  btt 从下往上
-->
<template>
  <el-drawer
      :visible.sync="getIsOpenDrawerVisiable"
      :direction="getDirection"
      :custom-class="getDirection == 'rtl'?'indexDrawerPageRight':'indexDrawerPageTop'"
      :show-close="false"
      :withHeader="false"
      :modal="false"
      :append-to-body="true"
      :modal-append-to-body="false"
  >
    <div class="drawer_index">
      <!--   头部   -->
      <div class="icon_arrow"
           @click.stop="$store.commit('updateIsHoverShowNav')"
      ><i :class="getIsOpenDrawerVisiable?'el-icon-caret-top':'el-icon-caret-bottom'"/>
      </div>
      <el-row :gutter="24" class="index_topBgc">
        <el-col :span="24">
          <img class="img_bgc" src="@/assets/images/tradtion_banner.png" alt="">
          <el-col :span="12">
            <div class="text_area">
              <div class="text_area_top">
                <span><img src="@/assets/images/svg/email.svg" alt="">邮箱</span>
                <span><img src="@/assets/images/svg/book.svg" alt="">图书馆</span>
                <span><img src="@/assets/images/svg/oneCard.svg" alt="">一卡通</span>
                <span><img src="@/assets/images/svg/flow.svg" alt="">校园网</span>
              </div>
              <div class="text_area_bottom">
                <p>未读邮箱<span style="color: #c4952d;"> 5433 </span>封</p>
                <span class="findMore">查看更多</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="icon_area">
              <div class="icon_area_li" v-for="(item,index) in iconList" :key="index"
                   @click.stop="toTipUrl(item)">
                <div class="icon_bgc">
                  <img :src="item.imgIcon" alt=""/>
                </div>
                <div class="li_div">{{ item.text }}
                  <span class="doatNum" v-if="item.number && item.number > 0">{{ item.number }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-col>
      </el-row>
      <!--   应用   -->
      <el-row :gutter="24" class="middle_app">
        <el-col :span="4"/>
        <el-col :span="17" class="app_title">
          <div class="app_tip">
            <div class="app_left">
              <span class="system" :class="appTip == 'system'? 'active' : ''" @click="chooseTip('system')">
                常用系统
              </span>
              <span class="favorite" :class="appTip == 'favorite'? 'active' : ''"
                    @click="chooseTip('favorite')">我的收藏
              </span>
            </div>
            <router-link to="/app/index">
              <span class="findMore" @click="isCloseDrawer">查看更多</span>
            </router-link>
          </div>
          <template v-if="appsList && appsList.length > 0">
            <div class="app_ul" :style="isOpenShow?'height:auto':'max-height:294px'">
              <el-col :span="3" class="app_li" v-for="item in appsList" :key="item.id">
                <div class="app_top" @click="toUri(item.uri)">
                  <img class="appImage" v-if="item.icon" :src="item.icon" @error="loadDefault"/>
                  <img class="appImage" v-else :src="defaultImg"/>
                  <p :title="item.text" class="overflowEllipsis">{{ item.text }}</p>
                </div>
                <div class="app_bootom">
                  <span class="apDetailIcon" @click="findAppDetail(item)">
                        <img src="@/assets/images/appDetail.png" alt=""/>
                  </span>
                  <span class="xing">
                    <Collect :id="item.id" :imgType="'xin'" :size="14"/>
                  </span>
                </div>
              </el-col>
            </div>
          </template>
          <template v-else>
            <el-skeleton v-if="appLoading"/>
            <div class="noDate addHeight" v-else>
              <img src="@/assets/images/noneSchedule.png" alt="">
            </div>
          </template>
          <el-col :span="24" v-if="appsList.length > 16">
            <div class="app_show" @click="isOpenShow = !isOpenShow">
              <i :class="`el-icon-caret-${isOpenShow? 'top': 'bottom'}`"/>已{{ isOpenShow ? '展开' : '收起' }}
              {{ appsList.length - 16 }} 个应用
            </div>
          </el-col>
        </el-col>
        <el-col :span="3"/>
      </el-row>
      <!--   新闻，通知聚合   -->
      <el-row :gutter="24" class="middle_notiAndSch">
        <el-col :span="4"/>
        <el-col :span="17" class="notiAndSch_title">
          <el-row :gutter="24">
            <el-col :span="12" class="notiAndSch_col_right">
              <div class="news_tip">
                <span class="title">新闻聚合</span>
                <router-link to="/notice/index">
                  <span class="more" @click="isCloseDrawer">更多</span>
                </router-link>
              </div>
              <template v-if="announceCentersList && announceCentersList.length > 0">
                <el-col :span="24" class="news_right_col">
                  <ul class="news_ul">
                    <li class="news_li" v-for="item in announceCentersList" :key="item.node.id">
                      <div class="li_left">
                        <p class="li_year">{{ getLocalTime(item.node.createDateTime, 'MM-DD') }}</p>
                      </div>
                      <div class="li_right overflowEllipsis">{{ item.node.title }}</div>
                    </li>
                  </ul>
                </el-col>
              </template>
              <template v-else>
                <el-skeleton v-if="announceLoading"/>
                <div class="noDate" v-else>
                  <img src="@/assets/images/noneNotice.png" alt="">
                </div>
              </template>
            </el-col>
            <el-col :span="12" class="notiAndSch_col_left">
              <div class="notiAndSch_tip">
                <span class="title">通知聚合</span>
                <router-link to="/notification/noread">
                  <span class="more" @click="isCloseDrawer">更多</span>
                </router-link>
              </div>
              <ul class="notiAndSch_ul">
                <template v-if="newAllUnreadNowInfos && newAllUnreadNowInfos.length > 0">
                  <li class="notiAndSch_li"
                      v-for="item in newAllUnreadNowInfos"
                      @click="pushUrl(isJSON(item.node.content)? filterVal(item.node.content,'url') : '')"
                  >
                    <div class="li_left">
                      <p class="li_month">{{ getLocalTime(new Date(item.node.createTime).getTime(), 'MM-DD') }}</p>
                    </div>
                    <div class="li_right">
                      <p class="text overflowEllipsis_1"
                         v-html="isJSON(item.node.content) ? changeMarkFromHtml(filterVal(item.node.content, 'des')) : changeMarkFromHtml(item.node.content)">
                      </p>
                    </div>
                  </li>
                </template>
                <template v-else>
                  <el-skeleton v-if="notificateLoading"/>
                  <div class="noDate" v-else>
                    <img src="@/assets/images/noneTodo.png" alt="">
                  </div>
                </template>
              </ul>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3"/>
      </el-row>
      <el-row :gutter="24" class="middle_resources">
        <el-col :span="4"/>
        <el-col :span="17" class="resources_div">
          <el-col :span="12" class="resources_col">
            <div class="resources_tip">
              <span class="title1">资源中心</span>
            </div>
            <div class="resources_contain">
              <div class="resources_contain_left">
                <div><img src="@/assets/images/svg/libraryBooks.svg" alt="">
                  <p>馆藏图书</p></div>
                <div><img src="@/assets/images/svg/software.svg" alt="">
                  <p>正版软件</p></div>
              </div>
              <div class="resources_contain_right">
                <div><img src="@/assets/images/svg/study.svg" alt="">
                  <p>学术资源</p></div>
                <div><img src="@/assets/images/svg/computed.svg" alt="">
                  <p>分析计算资源</p></div>
                <div><img src="@/assets/images/svg/robotSharing.svg" alt="">
                  <p>大型仪器共享</p></div>
                <div><img src="@/assets/images/svg/noveltySearch.svg" alt="">
                  <p>科技查新</p></div>
                <div><img src="@/assets/images/svg/journal.svg" alt="">
                  <p>校期刊中心</p></div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="canleder_col">
            <div class="canleder_tip">
              <span class="title1">日历</span>
            </div>
            <div class="canleder_contain">
              <WeekCanlendr/>
            </div>
          </el-col>
        </el-col>
        <el-col :span="3"/>
      </el-row>
      <!--   底部   -->
      <el-row :gutter="24">
        <div class="footer_div">
          {{ $store.state.userInfo && $store.state.userInfo.tenant && $store.state.userInfo.tenant.name }}
        </div>
      </el-row>
    </div>
  </el-drawer>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Collect from "@/components/collect";
import WeekCanlendr from "@/views/pc/index/modules/weekCanlendr";

export default {
  name: "newIndex",
  inject: [
    'getLocalTime', 'toUri',
    'changeMarkFromHtml', 'isJSON', 'filterVal',
  ],
  components: {Collect, WeekCanlendr},
  data() {
    return {
      isOpenShow: false,
      appTip: 'system',
      defaultImg: require('@/assets/images/application_center.png'),
      newsDefault: require('@/assets/images/newsDefault.png'),
      iconList: [
        {
          imgIcon: require('@/assets/images/todo_icon.png'),
          text: '我的待办',
          number: 0,
          routerLink: '/todoCenter/todo'
        }, {
          imgIcon: require('@/assets/images/nitificate_icon.png'),
          text: '我的会议',
          routerLink: '/schedule/index'
        }, {
          imgIcon: require('@/assets/images/schedule_icon.png'),
          text: '我的日程',
          routerLink: '/schedule/index'
        },
      ]
    }
  },
  watch: {
    getDataLength: {
      deep: true,
      immediate: true,
      handler(oldV) {
        if (oldV['todo'] > 0) {
          this.iconList[0].number = oldV['todo'];
        }
      }
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("appCenter", ["appList"]),
    ...mapState("todoCenter", ["dataSource"]),
    ...mapGetters("appCenter", ["getFavoriteApps"]),
    ...mapGetters("notificationCenter", ["getAllNowInfos"]),
    ...mapGetters("notificationCenter", ["getAllUnreadNowInfos"]),
    ...mapGetters(["getDirection"]),
    ...mapGetters(["getPosition"]),
    ...mapGetters(["getIsOpenDrawerVisiable"]),
    ...mapGetters("todoCenter", ["getDataLength"]),
    notificateLoading() {
      return this.$store.state.notificationCenter.loadingDataStart;
    },
    appLoading() {
      return this.$store.state.appCenter.loadingDataStart;
    },
    announceLoading() {
      return this.$store.state.announceCenter.loadingDataStart;
    },
    scheduleList() {
      return this.$store.getters['scheduleCenter/getScheduleList'];
    },
    todos_num() {
      return this.todos.length;
    },
    announceCentersLength() {
      return this.$store.getters['announceCenter/getAnnounceList'];
    },
    todos() {
      let items = this.dataSource["todo"];
      return items;
    },
    appsList() {
      if (this.appTip == 'system') {
        return this.appList;
      } else {
        return this.getFavoriteApps;
      }
    },
    announceCentersList() {
      let apps = [];
      if (this.$store.getters['announceCenter/getAnnounceList']
          && this.$store.getters['announceCenter/getAnnounceList'].length > 0
      ) {
        apps = this.$store.getters['announceCenter/getAnnounceList'].slice(0, 7);
      }
      return apps;
    },
    newAllUnreadNowInfos() {
      return this.getAllUnreadNowInfos.slice(0, 7);
    },
    getPositionMes() {
      return this.getPosition
          && this.getPosition.positions
          && this.getPosition.positions[0]
          && this.getPosition.positions[0].post.name;
    }
  },
  methods: {
    ...mapActions("oidcStore", ['signOutOidc']),
    isCloseDrawer() {
      this.$store.commit('updateDrawerVisiable', {type: this.getDirection});
    },
    loadDefault(e) {
      e.target.src = this.defaultImg;
    },
    chooseTip(val) {
      this.appTip = val;
    },
    //排序数组
    sortArr(arr, type) {
      let midArr = [];
      if (arr) {
        if (type == 'recommend') {
          midArr = arr.sort((a, b) => b.recommend - a.recommend);
        } else if (type == 'updated') {
          midArr = arr.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
        }
      }
      return midArr;
    },
    findAppDetail(item) {
      this.$store.commit('updateDrawerVisiable', {type: this.getDirection})
      let obj = {
        type: 2,
        appId: item.id
      }
      this.$store.dispatch('appCenter/addFavoriteMethods', obj);

      sessionStorage.setItem('currentAppDetail', JSON.stringify(item));
      this.$router.push('/commondetail');
    },
    pushUrl(val) {
      if (val) {
        window.open(val);
      }
    },
    toTipUrl(item) {
      this.$router.push(`${item.routerLink}`);
      this.$store.commit('updateDrawerVisiable', {type: 'rtl', isAutoChange: true});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/themes/css/traditionalIndex.scss";
</style>

<style scoped>
.el-drawer__wrapper {
  /*left: 95px !important;*/
  z-index: 0 !important;
}

::v-deep .indexDrawerPageRight {
  /*width: calc(100% - 95px) !important;*/
  width: 100% !important;
  height: calc(100% - 60px) !important;
  margin-top: 60px !important;
  background: #ffffff;
}

::v-deep .indexDrawerPageTop {
  /*width: calc(100% - 95px) !important;*/
  width: 100% !important;
  height: calc(100% - 60px) !important;
  /*margin-left: 95px !important;*/
  background: #ffffff;
}
</style>