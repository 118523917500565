import gql from 'graphql-tag'

const users = {
    //maker菜单
    menus: gql`query canvasmenus{
		canvasmenus{
			name
			text
			icon
			description
			parent
		}
	}`,
    // 获取登录人信息 (iam)
    me: gql`query me {
		me{
			id
			name
			username
			tenant{
                id
                name
                domain
                code
                config{
                    iconBase64
                }
			}
		}
	}`,
    // 查询用户列表 (iam)
    users: gql`query users($keywords:String, $first: Int, $offset: Int, $filter:Filter) {
		users(keywords:$keywords, first:$first, offset:$offset, filter:$filter){
		  edges{
			cursor
			node{
				id
				name
				account
				username
				cardNo
				phone
				email
				positions{
				  id
				  dept{
					code
					name
				  }
				  post{
					code
					name
				  }
				  startTime
				  endTime
				}
			}
		  }
		}
  	}`,
    // 查询部门岗位 (builtin)
    deptsAndPosts: gql`query deptsAndPosts($first: Int, $offset: Int) {
		departments(first:$first, offset:$offset){
		  edges{
			cursor
			node{
			  code
			  name
			  active
			  independent
			  parent
			}
		  }
		}
		posts(first:$first, offset:$offset){
		  edges{
			cursor
			node{
			  code
			  name
			  active
			  parentCode
			}
		  }
		}
  	}`,
    // 查询角色权限
    documentFileRoleService_search: gql`query documentFileRoleService_search($first: Int!, $offset: Float!, $filter: DocumentFileRoleFilter) {
      documentFileRoleService_search(first: $first, offset: $offset, filter: $filter) {
        resultInfo {
          totalCount
        }
        edges {
          id
          code
          createDateTime
          creator
          creatorId
          lastModifiedDateTime
          name
          memo
          status
          sortNum
          tenant
          tenantId
          upIds
          ups
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `,
}
export default users;