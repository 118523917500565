<template>
  <ul class="myNav">
    <li :class="['nav_tradition',getIsOpenDrawerVisiable?'active':'']"
        @click="$store.commit('updateDrawerVisiable',{type: 'rtl'})">
      <img
          :src="require('../../../assets/themes/images/' + (theme == 'monochrome' ? 'monochrome/' : '') + 'index1.png')"/>
      <p>首页</p>
    </li>
    <li v-for="(nav,index) in navs" :key="index"
        :class="['nav_' + nav.description, nav.description == active && !getIsOpenDrawerVisiable ? 'active' : '']"
        @click.stop="goPage(nav)">
      <img :src="require('../../../assets/themes/images/' + (theme == 'monochrome' ? 'monochrome/' : '') + nav.icon + '.png')"/>
      <p>{{ nav.text }}</p>
    </li>
    <el-popover placement="right" width="250" trigger="click" popper-class="popover_kjEnter">
      <ul class="kjAppUl">
        <li class="kjAppLi" v-for="(app,index) in allApps" :key="index" @click="toUri(app.uri)">
          <img v-if="app && app.icon" :src="app.icon" @error="loadDefault"/>
          <img v-else :src="defaultImg"/>
          <span class="overflowEllipsis" :title="app.text">{{ app.text }}</span>
        </li>
      </ul>
      <img slot="reference" class="kjEnter"
           :src="require('../../../assets/themes/images/' + (theme == 'monochrome' ? 'monochrome/' : '')+ fastApp + '.png')"
           alt="">
    </el-popover>
  </ul>
</template>
<script>
import {mapGetters, mapState} from "vuex"

export default {
  name: "myNav",
  inject: ['toUri', 'reload'],
  data() {
    return {
      navs: [],
      active: "",
      defaultImg: require('@/assets/images/application_center.png'),
      fastApp: 'fastApp'
    }
  },
  watch: {
    canvasmenusList: {
      immediate: true,
      handler(oldV) {
        if (oldV && oldV.length > 0) {
          this.navs = oldV;
        }
      }
    },
    $route: {
      immediate: true,
      handler(val) {
        if (val && val.matched && val.matched.length > 0) {
          for (let i = 0; i < val.matched.length; i++) {
            let nav = this.navs.filter(item => item.description == val.matched[i].name);
            if (nav && nav.length > 0) {
              this.active = nav[0].description;
              break;
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState(["theme"]),
    ...mapState('appCenter', ["appList"]),
    ...mapGetters('appCenter', ["getRecommends"]),
    ...mapGetters('appCenter', ["getFavoriteApps"]),
    ...mapGetters(["getIsOpenDrawerVisiable"]),
    ...mapState(['canvasmenusList']),
    // 快捷应用：有推荐度的优先展示，没有的展示收藏，在没有展示最近使用的
    allApps() {
      let newAppList = [];
      let recommendApp = this.appList.filter(item => item.recommend && item.recommend > 0);
      if (recommendApp.length > 0) {
        newAppList = this.sortArr(recommendApp, 'recommend');
      } else {
        if (this.getFavoriteApps.length > 0) {
          newAppList = this.getFavoriteApps;
        } else {
          newAppList = this.sortArr(this.appList, 'updated');
        }
      }
      return newAppList;
    }
  },
  methods: {
    goPage(nav) {
      this.$store.commit('updateDrawerVisiable', {type: 'rtl', isAutoChange: true});
      this.$router.push({name: nav.description})
      this.reload();
      sessionStorage.removeItem('selectInformation');
    },
    loadDefault(e) {
      e.target.src = this.defaultImg;
    },
    //排序数组
    sortArr(arr, type) {
      let midArr = [];
      if (arr) {
        if (type == 'recommend') {
          midArr = arr.sort((a, b) => b.recommend - a.recommend);
        } else if (type == 'updated') {
          midArr = arr.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
        }
      }
      return midArr;
    }
  }
}
</script>

<style lang="scss" scoped>
.myNav {
  text-align: center;
  font-size: 12px;
  padding: 0 5px;
  padding-top: 10px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  box-shadow: 1px 1px 5px #c3bebe;

  li {
    margin-bottom: 10px;
    padding: 8px 0px;
    cursor: pointer;
  }

  img {
    // margin-bottom: 5px;
  }

  p {
    margin: 0px;
  }
}
</style>
<style lang="scss">
.overflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kjEnter {
  width: 28px;
  position: absolute;
  bottom: 30px;
  left: 33%;
  cursor: pointer;
}

.popover_kjEnter {
  left: 80px !important;
}

.kjAppUl {
  max-height: 220px;
  overflow-x: scroll;

  .kjAppLi {
    display: inline-block;
    width: 28%;
    text-align: center;
    margin: 10px 10px 0 0;
    cursor: pointer;

    img {
      width: 35px;
    }

    span {
      font-size: 12px;
      display: block;
      height: 20px;
    }
  }
}
</style>