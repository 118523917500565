<template>
  <el-drawer
      size="100%"
      direction="rtl"
      :visible.sync="showMesCard"
      :before-close="handleClose"
      :append-to-body="true"
      :modal-append-to-body="false"
  >
    <div class="people_mes">
      <img src="@/assets/images/user_icon.png" alt="">
      <el-descriptions :title="positionMes.name">
        <el-descriptions-item label="用户名">{{ positionMes.username }}</el-descriptions-item>
        <el-descriptions-item label="人员证件号码">{{ positionMes.cardNo }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ positionMes.phone }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ positionMes.email }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="position_mes">
      <el-card v-for="item in positionMes.positions" :key="item.id">
        <div class="position_top">
          <p style="color: #9f2a3d;font-size: 16px;">{{ item.dept.name }}</p>
          <p style="font-weight: bold">{{ item.post.name }}</p>
        </div>
        <div class="position_bottom">
          <div class="position_left">
            <span>身份开始时间:</span>
            <span v-if="item.startTime">{{ getLocalTime(item.startTime, 'YYYY-MM-DD HH:mm:ss') }}</span>
          </div>
          <div class="position_right">
            <span>已在任：<span v-if="item.startTime && item.startTime < new Date().getTime()">
              {{ getAgoCurrentTime(item.startTime) }}
            </span>
            </span>
          </div>
        </div>
      </el-card>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "identityCard",
  inject: ['getAgoCurrentTime', 'getLocalTime'],
  filters: {
    getTime(str) {
      return str.replace(/(.+?)\-(.+?)\-(.+)/, "$1年$2月$3日");
    }
  },
  props: {
    showMesCard: {
      type: Boolean,
      default: () => false
    },
    positionMes: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeCard');
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  padding: 0 10px 0 20px;

  .el-drawer__header {
    line-height: 0;
    margin-bottom: 20px;
  }

  .el-drawer__body {
    padding: 0 20%;
  }


}

::v-deep .people_mes {
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 10px;
  border-bottom: 2px solid rgb(242, 242, 242);

  .el-descriptions {
    padding-left: 30px;

    .el-descriptions__header {
      margin-bottom: 0;

      .el-descriptions__title {
        height: 35px;
        line-height: 35px;
      }
    }

    &:not(.is-bordered) .el-descriptions-item__cell {
      display: block;
      padding-bottom: 3px;
    }

    .el-descriptions-item__label {
      font-size: 13px;
    }
  }
}

::v-deep .position_mes {
  height: 82%;
  overflow: scroll;
  margin-top: 10px;
  padding: 20px 10px 0 0;

  p {
    font-size: 14px;
  }

  span {
    font-size: 12px;
  }

  .el-card {
    width: 30%;
    display: inline-block;
    line-height: 20px;
    cursor: pointer;

    .el-card__body {
      height: 160px;
    }

    .position_top {
      border-bottom: 3px solid transparent;
      border-image: linear-gradient(to right, #b14d5f, #d52544) 1;

      p {
        margin-top: 0 !important;
        margin-bottom: 1em !important;
      }
    }

    .position_bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      .position_left {
        span {
          display: block;
          line-height: 20px;
        }
      }
    }
  }

  .el-card:nth-child(3n-1) {
    margin: 0 5%;
  }
}

/*
** 滚动条样式
*/
::v-deep .position_mes::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

::v-deep .position_mes::-webkit-scrollbar {
  width: 4px;
}

::v-deep .position_mes::-webkit-scrollbar-thumb {
  background-color: #dedcdc;
  border-radius: 8px;
}
</style>
<style>
.v-modal {
  /*z-index: 0 !important;*/
}
</style>