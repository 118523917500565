import moment from "moment";
import {marked} from 'marked'
/*
* 用于vue页面中混入
* */
export const myMixin = {
    provide() {
        return {
            initData: this.initData,
            assesTime: this.assesTime,  // 时间计算（天,小时）
            toUri: this.toUri,
            getLocalTime: this.getLocalTime,  // 获取年月日时分秒 'YYYY-MM-DD HH:mm:ss'
            getCurrentTimeParse: this.getCurrentTimeParse,
            splitTimeParse: this.splitTimeParse,    // 截取成默认拼接的日期格式  2 月 3
            getFirstDayOfWeek: this.getFirstDayOfWeek,   // 计算下一周
            addDay: this.addDay,            // 计算下一天
            reload: this.reload,            // 刷新数据
            getAgoCurrentTime: this.getAgoCurrentTime,   // 计算 X年X月X天
            changeMarkFromHtml: this.changeMarkFromHtml,  // markdown 转html
            getStringImgSrc: this.getStringImgSrc,  // 获取字符串中的 src
            isJSON: this.isJSON,  // 判断字符串是否是 json
            filterVal: this.filterVal,  // 过滤json中的 description url 字段
            changeNumToHan: this.changeNumToHan,  // 数组转汉字
        }
    },
    data() {
        return {
            RouterShow: true,
        }
    },
    methods: {
        assesTime(newTime) {
            let time = 0;
            if (newTime.toString().length > 10) {
                time = newTime;
            } else {
                time = newTime * 1000;
            }
            let minute = (moment(new Date().getTime()).diff(moment(time), 'minute'))
            let hour = (moment(new Date().getTime()).diff(moment(time), 'hour'))
            let day = (moment(new Date().getTime()).diff(moment(time), 'day'))
            let month = (moment(new Date().getTime()).diff(moment(time), 'month'))
            let year = (moment(new Date().getTime()).diff(moment(time), 'year'))
            if (minute < 60) {
                return `${minute + 1}分钟前`
            } else if (hour < 24) {
                return `${hour}小时前`
            } else if (day < 30) {
                return `${day}天前`
            } else if (month < 12) {
                return `${month}月前`
            } else {
                return `${year}年前`
            }
        },
        getLocalTime(time, format) {
            let timeStr;
            if (String(time).length > 10) {
                timeStr = moment(time).format(format);
            } else {
                timeStr = moment(time * 1000).format(format);
            }
            return timeStr;
        },
        //截取成默认拼接的日期格式  2 月 3
        splitTimeParse(date) {
            let monthDate = new Date(date).getMonth() + 1;
            let dayDate = new Date(date).getDate();
            return String(monthDate) + '月' + String(dayDate);
        },
        getCurrentTimeParse(time, cFormat) {
            if (arguments.length === 0) {
                return null
            }
            const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
            let date
            if (typeof time === 'object') {
                date = time
            } else {
                if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                    time = parseInt(time)
                }
                if ((typeof time === 'number') && (time.toString().length === 10)) {
                    time = time * 1000
                }
                date = new Date(time)
            }
            const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay(),
            }
            const time_str = format.replace(/{([ymdhisaw])+}/g, (result, key) => {
                const value = formatObj[key]
                if (key === 'a') {
                    return ['日', '一', '二', '三', '四', '五', '六'][value]
                }
                return value.toString().padStart(2, '0')
            })
            return time_str
        },
        getFirstDayOfWeek(date) {
            var day = date.getDay() || 7;
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
        },
        addDay(date, num) {
            return new Date(date.getTime() + 1000 * 60 * 60 * 24 * num);
        },
        toUri(appUrl, preview, remark) {
            var isMobile = false;
            var flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            if (flag != null) {
                isMobile = true;
            }
            let query = {};
            if (isMobile) {
                query.back = 1;
                query.x_posted = true;
            }
            if (remark === true || remark === false) {
                query.showRemark = remark;
            }
            if (preview) {
                if (appUrl.indexOf('preview') === -1) {
                    query.preview = preview === 'yes' ? true : false;
                } else {
                    if (preview === 'yes') {
                        appUrl = appUrl.replace("preview=false", "preview=true")
                    } else {
                        appUrl = appUrl.replace("preview=true", "preview=false")
                    }
                }
            }
            let len = Object.keys(query).length;
            if (len > 0) {
                let after_url = '';
                let i = 1;
                for (let key in query) {
                    after_url = i < len ? after_url + `${key}=${query[key]}&` : after_url + `${key}=${query[key]}`;
                    i++;
                }
                if (appUrl.indexOf("?") > -1) {
                    appUrl += "&" + after_url;
                } else {
                    appUrl += "?" + after_url;
                }
            }
            if (isMobile) {
                location.href = appUrl;
            } else {
                window.open(appUrl);
            }
        },
        reload() {
            this.RouterShow = false;
            this.$nextTick(() => {
                this.RouterShow = true;
            });
        },
        initData(route, bool) {
            let dataName = route || this.$route.name;
            let obj = {};
            obj.dataName = dataName;
            if (bool) {
                obj.limitRefresh = true;
            }
            this.$store.dispatch('todoCenter/ClearQ', obj);
        },
        // 计算 X年X月X天
        getAgoCurrentTime(time) {
            var diff = '';
            var time_diff = new Date().getTime() - time;
            var days = Math.floor(time_diff / (1000 * 24 * 3600));
            var month = 0;
            var year = 0;
            if (days > 0 && days < 30) {
                diff = `${days}天`;
            }
            if (days > 30 && days < 365) {
                month = Math.floor(days / 30);
                days = days - (month * 30);
                diff = `${month}月${days}天`;
            }
            if (days > 365) {
                year = Math.floor(days / 365);
                let mid = days - (year * 365);
                if (mid > 30) {
                    month = Math.floor(mid / 30);
                    days = mid - (month * 30);
                } else {
                    days = mid;
                }
                diff = `${year}年${month}月${days}天`;
            }
            return diff;
        },
        changeMarkFromHtml(val) {
            var flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            if (val) {
                let newVal = marked(val);
                let lastVal = newVal.replace(/\n/g, "<br>");
                let TwoVal = lastVal.replace(/&gt;/g, "<p>")
                // 移动端将解析html代码成文本
                if (flag){
                    let changeDiv = document.createElement('div');
                    changeDiv.className = 'changeDiv'
                    changeDiv.innerHTML = TwoVal;
                    return changeDiv.getElementsByTagName('p')[0].innerHTML;
                }else {
                    return TwoVal;
                }
            } else {
                return '';
            }
        },
        getStringImgSrc(content) {
            let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
            let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; //匹配图片中的src
            let arr = content.match(imgReg); //筛选出所有的img
            let srcArr = [];
            if (arr && arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    let src = arr[i].match(srcReg);
                    srcArr.push(src[1]);
                }
            }
            return srcArr[0];
        },
        isJSON(str) {
            if (typeof str == 'string') {
                try {
                    let obj = JSON.parse(str);
                    if (obj && typeof obj == 'object') {
                        return true;
                    } else {
                        return false;
                    }
                } catch (e) {
                    return false;
                }
            }
        },
        filterVal(val, type) {
            let obj = JSON.parse(val);
            if (type == 'url') {
                return obj.url;
            } else if (type == 'des') {
                return obj.description;
            }
        },
        changeNumToHan(num) {
            var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
            var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿'];
            if (!num || isNaN(num)) return '零';
            var english = num.toString().split('');
            var result = '';
            for (var i = 0; i < english.length; i++) {
                var des_i = english.length - 1 - i// 倒序排列设值
                result = arr2[i] + result
                var arr1_index = english[des_i]
                result = arr1[arr1_index] + result
            }
            result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
            result = result.replace(/零+/g, '零');
            result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
            result = result.replace(/亿万/g, '亿');
            result = result.replace(/零+$/, '');
            result = result.replace(/^一十/g, '十');
            return result;
        }
    }
}

/*
* 默认工具包,任意地方均可使用
* */
export const publicMenthods = {
    getCurrentTimeParse(time, cFormat) {
        if (arguments.length === 0) {
            return null
        }
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay(),
        }
        const time_str = format.replace(/{([ymdhisaw])+}/g, (result, key) => {
            const value = formatObj[key]
            if (key === 'a') {
                return ['日', '一', '二', '三', '四', '五', '六'][value]
            }
            return value.toString().padStart(2, '0')
        })
        return time_str
    },

    getMobile() {
        var flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (flag === null) {
            return false;
        } else {
            return true;
        }
    }
}