import gql from 'graphql-tag'

const builtin = {
    getDepts: gql`query($first: Int, $offset: Int, $filter: Filter, $keywords: String) {
        departments(first: $first offset: $offset filter: $filter keywords: $keywords) {
          edges {
            node {
              id
              name
              code
              parent
              tags
              independent
              tenantId
              timestamp
              disabled
              source
              description
            }
            cursor
          }
          pageInfo {
            hasNextPage
          }
          totalCount
        }
    }`,
    getUsers: gql`query($first: Int $offset: Int $filter: Filter $id: ID $position: String $keywords: String) {
        users(first: $first offset: $offset filter: $filter id: $id position: $position keywords: $keywords) {
          edges {
            node {
              id
              name
              account
              accountFriendly
              tenantId
              timestamp
              email
              phone
              express
              disabled
              source
              positions {
                dept {
                  id
                  name
                  code
                  parent
                  tags
                  independent
                  tenantId
                  timestamp
                  disabled
                  source
                  description
                }
                post {
                  id
                  name
                  code
                  tags
                  formal
                  tenantId
                  timestamp
                  disabled
                  source
                  description
                }
              }
            }
            cursor
          }
          pageInfo {
            hasNextPage
          }
          totalCount
        }
    }`,
    getPosts: gql`query($id: ID $first: Int $last: Int $before: String $after: String $offset: Int $filter: Filter $keywords: String $tags: String) {
        posts(id: $id first: $first last: $last before: $before after: $after offset: $offset filter: $filter keywords: $keywords tags: $tags) {
          edges {
            node {
              id
              name
              code
              tags
              formal
              tenantId
              timestamp
              disabled
              source
              description
            }
            cursor
          }
          pageInfo {
            hasNextPage
          }
          totalCount
        }
    }`,
    canvasmenus: gql`query canvasmenus($filter: Filter){
        canvasmenus(filter: $filter){
            name
            description
            parent
            text
            uri
            icon
        }
    }`,
}
export default builtin;