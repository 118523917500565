import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from 'vue-apollo'
import apolloProvider from './apolloConfig'
import axios from './axiosConfig'
Vue.prototype.$axios = axios;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(VueApollo);

Vue.config.productionTip = false;
var body = document.getElementsByTagName("body");
body[0].setAttribute("data-theme", store.state.theme);

new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
