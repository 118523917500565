import gql from 'graphql-tag'

const appCenter = {
    userApps: gql`query userApps($user:String){
		userApps(user:$user){
				id,
				name,
				uri,
				text,
				kind,
				code,
				abbreviation,
				description,
				tags,
				icon,
				palette,
				department,
				contact,
				recommend,
				rating,
				rated,
				system,
				visible,
				created,
    			updated,
    			template{
					id
					name
					text
					tags
            	}
			  	templateEntry{
					screens
					userFilters
			  	}
		}
	}`,
    // 记录用户点击收藏访问次数
    createAppOperationRecord: gql`mutation createAppOperationRecord($user:String, $record: AppOperationRecordInput!){
		createAppOperationRecord(user:$user, record: $record){
			id
		}
	}`,
    // 记录用户点击取消收藏访问次数
    cancelAppCollect: gql`mutation cancelAppCollect($user:String, $app: String!){
		cancelAppCollect(user:$user, app: $app){
			id
		}
	}`,
    // 查询用户收藏列表
    userFavoriteApps: gql`query userFavoriteApps{
		userFavoriteApps{
			id
		}
	}`,
    //查询应用评价
    appReviews: gql`query appReviews($filter: AppReviewFilter){
       appReviews(filter:$filter){
    	  totalCount
       }
    }`,
    //查询我的评分及评价
    userAppReviews: gql`query userAppReviews($user:String, $filter: AppReviewFilter){
       userAppReviews(user:$user,filter:$filter){
    	  edges{
    		 cursor
    		 node{
    			name
    			rate
    			text
    			user{
    			   account
    			   openid
    			   text
    			}
    			created
    			updated
    		 }
    	  }
    	  pageInfo{
			  hasNextPage
    	  }
    	  totalCount
       }
    }`,
    //新增应用评价
    createAppReview: gql`mutation createAppReview($user:String, $review: AppReviewInput!) {
    	 createAppReview(user:$user,review:$review){
			name
    	 }
       }`,
    //最近使用应用列表
    userRecentApps:gql`query userRecentApps($first: Int,$offset: Int){
        userRecentApps(first: $first,offset: $offset){
            id
            name
            uri
            text
            code
            description
            tags
            icon
            department
            recommend
            rating
            rated
            system
            created
            updated
        }
    }`,
    //查询标签列表
    appTags:gql`query appTags{
        appTags{
            id
            text
            icon
            sort
            updated
        }
    }`
}
export default appCenter;