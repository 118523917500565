<template>
  <div class="layoutContainer">
    <div class="top">
      <my-header/>
    </div>
    <div class="bottom">
      <div class="bottomLeft" :class="isHoverShowNav?'isHoverShowNav':''">
        <my-nav/>
      </div>
      <div class="bottomRight">
        <router-view v-if="RouterShow"/>
      </div>
    </div>
    <component :is="'NewIndex'"/>
  </div>
</template>

<script>
import {myMixin} from "@/mixins/utils.js";
import myHeader from "./myHeader"
import myNav from "./myNav"
import {mapState} from "vuex"
import NewIndex from "@/views/pc/index/traditionalIndex";

export default {
  name: "index",
  components: {myNav, myHeader,NewIndex},
  mixins: [myMixin],
  computed: {
    ...mapState(['theme']),
    ...mapState(['isHoverShowNav'])
  }
}
</script>

<style lang="scss">
@import "~@/assets/themes/css/layout.scss";

.layoutContainer {
  height: 100%;
  position: relative;

  .top {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 5px rgba(71, 71, 71, 0.2);
    z-index: 1;
  }

  .bottom {
    position: absolute;
    top: 60px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }

  .bottomLeft {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 95px;
    border-right: 1px #D1D7DB solid;
  }

  .isHoverShowNav {
    z-index: 999;
    background-color: #f0f0f0;
  }

  .bottomRight {
    position: absolute;
    left: 95px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    //box-shadow: -5px 5px 10px rgba(71,71,71,0.2);
  }
}

.main {
  background: red;
}
</style>
