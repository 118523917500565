<template>
  <div id="app" :style="{minWidth:!getMobile? '1000px' : ''}">
    <router-view></router-view>
  </div>
</template>
<script>
import layout from "@/views/pc/layout/index"
import {mapActions} from "vuex"

export default {
  components: {layout},
  computed: {
    getAuth() {
      return this.$store.getters["oidcStore/oidcAccessToken"];
    }
  },
  watch: {
    getAuth: {
      handler(value) {
        if (value) {
          this.userLoaded();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(["getLoginInfo"]),
    userLoaded() {
      this.getLoginInfo();
    },
    getMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (flag === null) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>
<style lang="scss">
html, body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  font-size: 14px;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: #f9f9f9;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

ul {
  li {
    list-style: none;
  }
}
</style>
